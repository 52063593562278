import React, {useState,useEffect, ChangeEvent} from "react"
import styled from "styled-components";
import Layout from "../layout";
const StyledWheel = styled.iframe`
  min-height: 500px;
`;
const PickList = styled.div`
  padding: 20px 0;
`;
const Wrapper = styled.div`
  background: #000;
  z-index: 20;
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const OptionItem = styled.label`
  margin-right: 10px;
`
const marketingParticipants = [
  'Bjorn',
  'Danyon',
  'Joe',
  'Jon',
  'Michael',
  'Mickey',
  'Noah',
];
const kopsParticipants = [
  'Alice',
  'Bjorn',
  'Jason',
  'Jordan',
  'Kenny',
  'Mike',
  'Nate',
  'Rem',
  'Risa',
];
let baseParticipantList = marketingParticipants;

const IndexPage = () => {
  const [participants, setparticipants] = useState<Array<string>>(baseParticipantList);
  useEffect(()=>{
    setparticipants(marketingParticipants);
    const queryString = window.location.search;
    if(queryString !== ''){
      if(queryString.split('=')[1] === 'kops') {
        setparticipants(kopsParticipants);
        baseParticipantList = kopsParticipants;
      }
    }
  }, []);
  const handleCheck = (e:ChangeEvent) => {
    let updatedList = [...participants];
    const currCheck = e.target as HTMLInputElement;
    if (currCheck.checked) {
      updatedList = [...participants, currCheck.value];
    } else {
      updatedList.splice(participants.indexOf(currCheck.value), 1);
    }
    setparticipants(updatedList);
  };

  return (
    <Layout>
      <Wrapper>
        <PickList>
          {baseParticipantList.map((participant)=> (<OptionItem key={participant}><input type="checkbox" defaultChecked={true} onChange={(e) => {handleCheck(e)}} name="participant_list" value={participant} /> {participant}</OptionItem>))}
        </PickList>
        <StyledWheel src={`https://pickerwheel.com/emb?choices=${participants.toString()}`} />
      </Wrapper>
    </Layout>
  )
}

export default IndexPage
